/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {

    $('#hundreds__contact-form #hundreds__contact_submit-button').on('click', function(e){
        console.log('contact');
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateContactForm();
        });
    });

    $('#hundreds__newsletter-form #hundreds__newsletter_submit-button').on('click', function(e){
        console.log('newsletter');
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateNewsletterForm();
        });
    });

    $('.tablinks').on('click', function(e){
        var tab = $(this).attr('data-id');


        var tabcontent = document.getElementsByClassName("tabcontent");
        for (var i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        var tablinks = document.getElementsByClassName("tablinks");
        for (var i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        $('#' + tab + '_tab').addClass('active');
        document.getElementById(tab).style.display = "block";
    })

    $('[id^="checkPost"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validatePostForm();
        });
    });

    $('[id^="checkProject"]').on('click', function(e){
        $('#status').val($(this).attr('data-type'));

        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateProjectForm();
        });
    });

    $('#checkMember').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateMemberForm();
        });
    });

    $('#checkTenderDownload').on('click', function(e){
        e.preventDefault();
        $('#hundreds__newsletter_loader').removeClass("hidden");

        getValidationFunctions().then(validateModule => {
            validateModule.validateDownloadTenderForm();
        });
    });

    $('#checkAccess').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateAccessForm();
        });
    });

    $('#checkProfile').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateProfileForm();
        });
    });

    $('[id^="notification_"]').on('change', function(e){
        var url = $('#update_notifications-form').data('action');
        var value = $(this).val();
        var type = $('#type').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {value:value, type:type},
            async: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data && typeof data == 'object') {
                   console.log('success');
                }else {
                    console.log('error');
                }
            },
        });
    });

    if($('[id^="delete_content-"]')){
        if ($('[id^="delete_content-"]').on('click', function(){
            var url = $(this).attr('data-url');
            $('#content_delete_link').attr("href", url);
        }));
    };

    $('#addOtherAddress').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateAddressForm();
        });
    });

    $('#readNotifications').on('click', function (e) {
        e.preventDefault();
        var id =  'all';
        var url = $('#update_notifications').val();
        var type = $(this).attr('data-type');

        switch (type){
            case 'presentacions':
                type = 'tender';
                break;
            case 'obertures':
                type = 'offer';
                break;
            case 'adjudicacions':
                type = 'adjudication';
                break;
            default:
                type = 'tender';
        }

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:type},
            async: false,
            success: function(data) {

                switch (type){
                    case 'tender':
                        $("[id^='removeNotification_']").remove();
                        break;
                    case 'offer':
                        $("[id^='removeNotificationOb']").remove();
                        break;
                    case 'adjudication':
                        $("[id^='removeNotificationAdj_']").remove();
                        break;
                    default:
                        $("[id^='removeNotification_']").remove();
                }
            },
        });
    });

    $('[id^="removeNotificationEvent_"]').on('click', function (e) {
        var id =  $(this).attr('data-id');
        var url = $('#update_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'event'},
            async: false,
            success: function(data) {
                $("#removeNotificationEvent_"+  id).remove();
            },
        });
    });

    $('[id^="removeNotificationPost_"]').on('click', function (e) {
        var id =  $(this).attr('data-id');
        var url = $('#update_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'post'},
            async: false,
            success: function(data) {
                $("#removeNotificationPost_"+  id).remove();
            },
        });
    });


    $('[id^="removeNotificationLegislation_"]').on('click', function (e) {
        var id =  $(this).attr('data-id');
        var url = $('#update_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'legislation'},
            async: false,
            success: function(data) {
                $("#removeNotificationLegislation_"+  id).remove();
            },
        });
    });


    $('#tenders-table-full-1').on('click', '[id^="removeNotification_"]', function (e) {
        e.preventDefault();
        var id =  $(this).attr('data-id');
        var url = $('#update_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'tender'},
            async: false,
            success: function(data) {
                $("#removeNotification_"+  id).remove();
            },
        });
    });

    $('#tenders-table-full-2').on('click', '[id^="removeNotificationOb_"]', function (e) {
        e.preventDefault();
        var id =  $(this).attr('data-id');
        var url = $('#update_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'offer'},
            async: false,
            success: function(data) {
                $("#removeNotificationOb_"+  id).remove();
            },
        });
    });

    $('#tenders-table-full-3').on('click', '[id^="removeNotificationAdj_"]', function (e) {
        e.preventDefault();
        var id =  $(this).attr('data-id');
        var url = $('#update_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'adjudication'},
            async: false,
            success: function(data) {
                $("#removeNotificationAdj_"+  id).remove();
            },
        });
    });


    $('#my-tenders-table').on('click', '[id^="saveTender_"]', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var url = $('#update_tender_save-form').data('url');
        var action = $(this).attr('data-action');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {tender:id, action:action},
            async: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data && typeof data == 'object') {
                    if(action == 'add'){
                        $('#icon_save_' + id).attr('src', 'images/table-filter-full.svg')
                        $('#saveTender_' + id).attr('data-action', 'remove')
                    }else{
                        $('#icon_save_' + id).attr('src', 'images/table-filter.svg')
                        $('#saveTender_' + id).attr('data-action', 'add')
                    }
                }else {
                }
            },
        });
    });

    $('#tenders-table-full-1').on('click', '[id^="saveTender_"]', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var url = $('#update_tender_save-form').data('url');
        var action = $(this).attr('data-action');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {tender:id, action:action},
            async: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data && typeof data == 'object') {
                    if(action == 'add'){
                        $('#icon_save_' + id).attr('src', 'images/table-filter-full.svg')
                        $('#saveTender_' + id).attr('data-action', 'remove')
                    }else{
                        $('#icon_save_' + id).attr('src', 'images/table-filter.svg')
                        $('#saveTender_' + id).attr('data-action', 'add')
                    }
                }else {
                }
            },
        });
    });

    $('#tenders-table-full-2').on('click', '[id^="saveTenderOb_"]', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var url = $('#update_tender_save-form').data('url');
        var action = $(this).attr('data-action');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {tender:id, action:action},
            async: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data && typeof data == 'object') {
                    if(action == 'add'){
                        $('#icon_saveOb_' + id).attr('src', 'images/table-filter-full.svg')
                        $('#saveTenderOb_' + id).attr('data-action', 'remove')
                    }else{
                        $('#icon_saveOb_' + id).attr('src', 'images/table-filter.svg')
                        $('#saveTenderOb_' + id).attr('data-action', 'add')
                    }
                }else {
                }
            },
        });
    });

    $('#tenders-table-full-3').on('click', '[id^="saveAdjTender_"]', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var url = $('#update_tender_save-form').data('url');
        var action = $(this).attr('data-action');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {tender:id, action:action},
            async: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data && typeof data == 'object') {
                    if(action == 'add'){
                        $('#icon_saveAdj_' + id).attr('src', 'images/table-filter-full.svg')
                        $('#saveAdjTender_' + id).attr('data-action', 'remove')
                    }else{
                        $('#icon_saveAdj_' + id).attr('src', 'images/table-filter.svg')
                        $('#saveAdjTender_' + id).attr('data-action', 'add')
                    }
                }else {
                }
            },
        });
    });

    $('#tenders-dashboard-table').on('click', '[id^="saveTender_"]', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var url = $('#update_tender_save-form').data('url');
        var action = $(this).attr('data-action');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {tender:id, action:action},
            async: false,
            success: function(data) {
                data = JSON.parse(data);

                if(data && typeof data == 'object') {
                    if(action == 'add'){
                        $('#icon_save_' + id).attr('src', 'images/table-filter-full.svg')
                        $('#saveTender_' + id).attr('data-action', 'remove')
                    }else{
                        $('#icon_save_' + id).attr('src', 'images/table-filter.svg')
                        $('#saveTender_' + id).attr('data-action', 'add')
                    }
                }else {
                }
            },
        });
    });

});

